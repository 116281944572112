.error-box{
    display: flex;
    align-items: center;
    flex-direction: column;

    position: absolute;

    top: 40px;
    right: 29%;

    background-color: aliceblue;
    width: 500px;
    border-radius: 20px;
}
.error-head h1{
    color: red;
    font-size: 40px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: aliceblue;
}
.error-p{
    background-color: aliceblue;
    font-size: 28px;
    padding: 10px;
}
.error-click{
    margin: 10px;
    color: azure;
    background-color: blue;
    width: 50px;
    height: 25px;

    border-radius: 20px;
}