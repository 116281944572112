.register{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 90vh;
    padding: 45px;
}

.left-header-logo{
    font-size: 50px;
    flex-wrap: wrap;
    width: 45vw;

    color: #5061FF;
    font-family: Arial, Helvetica, sans-serif;
}

.right-side-register {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #555555;

    border-radius: 20px;
}

.form-header{
    color: #FFFFFF;
    background-color: #555555;
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;

    margin: 15px;
}

.right-side-register form{
    display: flex;
    flex-direction: column;
    background-color: #555555;
}

.email{
    width: 300px;
    height: 35px;

    border-radius: 20px;
    background-color: #1B1A1A;
    margin: 10px;
    border: none;
    outline: none;

    font-size: 16px;
    padding: 10px;
    color: #FFFFFF;
}

.password{
    width: 300px;
    height: 35px;

    border-radius: 20px;
    background-color: #1B1A1A;
    margin: 10px;
    border: none;
    outline: none;

    font-size: 16px;
    padding: 10px;
    color: #FFFFFF;
}

.confirmPassword{
    width: 300px;
    height: 35px;

    border-radius: 20px;
    background-color: #1B1A1A;
    margin: 10px;
    border: none;
    outline: none;

    font-size: 16px;
    padding: 10px;
    color: #FFFFFF;
}

.register-button{
    margin: 10px;
    width: 70px;
    height: 30px;
    border-radius: 20px;
    background-color: #353CE7;
    border: none;
    color: #FFFFFF;
    align-self: center;
}

.link-to-login{
    background-color: #555555;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 200;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    text-decoration: none;
    outline: none;
}

@media only screen and (max-width: 1400px) {
    .register {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        height: 80vh;
    }
    .left-header-logo {
        font-size: 30px;
        flex-wrap: wrap;
        width: 70vw;

        color: #5061FF;
        font-family: Arial, Helvetica, sans-serif;
    }
    .right-side-register {
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: #555555;

        border-radius: 20px;
    }
    .form-header {
        color: #FFFFFF;
        background-color: #555555;
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;

        /* margin: 15px; */
    }
}