.Main {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: #555555;
  border-radius: 20px;
  width: 30vw;
  height: 85vh;
  flex-wrap: wrap;
}

.chatLayout {
  display: flex;
}
