.all-user {
  width: 100%;
  height: 74.5vh;
  padding: 20px;
  border-radius: 20px;

  color: white;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  text-decoration: none;
}

.all-user-head {
  align-self: center;
  width: 100%;
}

.all-user-lists {
  text-decoration: none;
  list-style: none;
}
.second-all-user-lists{
  list-style: none;
}
.second-all-user-div{
  outline: none;
  border: none;
  padding: none;
}
