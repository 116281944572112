.chat-form {
  height: 40px;
  border-radius: 20px;
  padding: 0px 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-form button {
  border: none;
  margin: 0px 5px;
  width: 25px;
  height: 25px;
}

.emoji-picker-icon {
  width: 25px;
  margin: 0px 8px;
  outline: none;
  border-radius: 50%;
  border: none;
  color: blue;
}

.message-input {
  margin: 0px 10px;

  outline: none;
  border: none;

  background-color: #555555;
  height: 35px;
  border-radius: 20px;
  padding: 0px 20px;
  font-size: 16px;
  color: white;
  width: 50vw;
}

.submit-icon {
  color: blue;
}
