.search {
  width: 100%;
  background-color: #555555;
  display: flex;
  flex-direction: row-reverse;
}

.search-icon {
  width: 40px;
  height: 40px;
  background-color: #555555;
  color: white;
}

.search-box {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: none;

  padding: 20px;
  color: white;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
