.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #555555;
    padding: 5px 40px;
    margin: 10px 20px; 
    border-radius: 20px;
}

.logo span {
    color: #FFFFFF;
    font-size: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #555555;
}

.logo a{
    text-decoration: none;
}

.right-side-head{
    background-color: #555555;
}

.image img{
    width: 30px;
    height: 30px;
    background-color: #555555;
    /* border: 0.5px solid black; */
    border-radius: 50%;
}

.image{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

@media only screen and (max-width: 1400px) {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #555555;
        padding: 5px 40px;
        margin: 5px 10px;
        border-radius: 20px;
        width: 90vw;
    }
    .logo span {
        color: #FFFFFF;
        font-size: 30px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        background-color: #555555;
    }
}