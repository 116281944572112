.user-layout{
  display: flex;
  align-items: center;

  background-color: #555555;
  border-radius: 20px;
  padding: 10px 0px;
  width: 260px;
  margin: 10px;
  cursor: pointer;
}
.user-layout img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 10px;
}
.user-layout-name{
  color: white;
  background-color: #555555;
}