.message {
  list-style: none;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.receiver-text {
  margin: 10px 0px;
}
.receiver-text span {
  background-color: blue;
  color: white;
  padding: 6px 10px;
  border-radius: 20px;
}

.sender{
  display: flex;
  flex-direction: column;
  align-items: end;
}

.sender-text {
  margin: 10px 0px;
}
.sender-text span {
  background-color: #555555;
  color: white;
  padding: 6px 10px;
  border-radius: 20px;
}

.time-ago{
  color: white;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
