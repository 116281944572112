.chat-box {
  background-color: #555555;
  width: 65vw;
  margin: 20px;
  height: 85vh;

  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
}

.chat-head {
  height: 60px;
  border-radius: 20px;
}

.chat-head Contact{
  position: relative;
  top: 0px;
}
.message-box {
  width: 100%;
  height: 75vh;
  margin: 15px 0px;
  border-radius: 20px;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}